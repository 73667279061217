import { useEffect, useState } from 'react';
import {
  Button,
  // Card,
  // CardActions,
  // CardContent,
  Grid,
  Typography,
  Alert,
  Box,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { connect, useSelector } from 'react-redux';
import { getProjects } from '../../actions/forms';
import { useDispatch } from 'react-redux';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { getUserDetails } from '../../actions/auth';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCarRppbProjects, getCompanyUserCarRppbs } from '../../actions/tigercat-burns/tigercat-base-actions';
import moment from 'moment'
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import { FeedstockDelivered } from '../sections/tigercatburns/dashboard/FeedstockDelivered';
import { BiocharProduction } from '../sections/tigercatburns/dashboard/BiocharProduction';

const Home = ({ projects, userType }) => {
  const { isBiocharOrConservationBurnUser, isCarbonFootprintUser, isTigercatBurnsUser } = userType
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [activeCarRppb, setActiveCarRppb] = useState(null);
  const [activeCarRppbProject, setActiveCarRppbProject] = useState(null);
  const [hide, setHide] = useState(true);
  const [hideProjects, setHideProjects] = useState(true);

  const { userData } = useSelector(state => state.auth);
  const carRppbProjects = useSelector(state => state.tigercatBurnsBaseReducer.carRppbProjects.data)
  const carRppbs = useSelector(state => state.tigercatBurnsBaseReducer.carRppbs.data)

  const handleSetActiveCarRppb = (carRppb) => {
    setActiveCarRppb(carRppb)
    dispatch(getCarRppbProjects(carRppb.id))
  }
  const userCompanyId = userData?.tigercatBurnsUser?.companyId;
  useEffect(() => {
    if (userCompanyId)
      dispatch(getCompanyUserCarRppbs(userCompanyId))
  }, [dispatch, userCompanyId])

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (userData)
      if (!userData.carbonFootprintUser && !userData.tigercatBurnsUser)
        dispatch(getProjects(userData?.farmId));
  }, [dispatch, userData]);

  const handleNavigate = (project) => {
    navigate(`/project/${project.id}?nickname=${project.nickname}&projectIdentifier=${project.projectId}`)
  }

  useEffect(() => {
    // Scroll to the bottom of the viewport
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }, [activeCarRppb, activeCarRppbProject]);

  const renderHomeContent = () => {
    if (isBiocharOrConservationBurnUser)
      return (
        <>
          <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
            Quick Project Access
          </Typography>
          <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'}>
            {projects?.map((project, index) => (
              <>
                <Box className="biochar-project biochar-project-home" key={index}
                  onClick={() => handleNavigate(project)}>
                  <Typography sx={{ fontSize: '18px' }}><BookmarkBorderOutlinedIcon sx={{ mb: '-7px' }} /> {project.projectId}</Typography>
                  <Typography sx={project.nickname ? { fontSize: '14px' } : { visibility: 'hidden' }} mt={3}><strong>Nickname:</strong> {project.nickname}</Typography>
                  <Typography sx={{ fontSize: '14px' }}><strong>Date Created:</strong> {project.created_at.split('T')[0]}</Typography>
                </Box >
              </>
            ))}
          </Grid >
          <Alert severity='warning' sx={{ width: '100%', position: 'absolute', bottom: '0', left: '0', paddingLeft: '80px' }}>
            Please email wihan@ikhala.tech for the "Web App User Manual" and the "CHARR Mobile App Field Guide" as well as other biochar support documents such as the "Biochar Toolkit Overview" and "Tips for Data Quality Improvements"
          </Alert>
          {/* {console.log(userData)} */}
        </>
      )
    else if (isCarbonFootprintUser)
      return (
        <>
          <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
            Carbon Footprinting
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 3 }}>
            You have access to the carbon footprinting tool. Click on the link below to access the carbon footprinting section.
          </Typography>
          <Button variant="contained" onClick={() => navigate('/carbon-footprinting')}>
            Carbon Footprting Tool
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Alert severity='warning' sx={{ width: '100%', position: 'absolute', bottom: '0', left: '0', paddingLeft: '80px' }}>
              Please email wihan@ikhala.tech should you have any questions or need assistance.
            </Alert>
          </Box>
        </>
      )
    else if (isTigercatBurnsUser)
      return (
        <>
          <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
            Earth Foundries Carbonization
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 3 }}>
            You have access to the Earth Foundries Operations App. Click on the link below to access the biochar data management tool.
          </Typography>
          <Button variant="contained" onClick={() => navigate('/car-reporting')}>
            Biochar Data Management Tool
          </Button>
          <Alert sx={{ mt: 3 }} severity='warning'>
            Please email wihan@ikhala.tech should you have any questions or need assistance.
          </Alert>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setHide(!hide)}
            >
              <Typography>{hide ? "Hide" : "Expand"} CAR RPPBs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                {carRppbs?.map((carRppb, index) => {
                  return (
                    <Button
                      sx={{ mr: 2 }}
                      key={index}
                      variant={carRppb.id === activeCarRppb?.id ? 'contained' : 'outlined'}
                      color='primary'
                      onClick={() => handleSetActiveCarRppb(carRppb)}>
                      {carRppb.reportingEntityName}
                    </Button>
                  )
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
          {
            activeCarRppb &&
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => setHideProjects(!hideProjects)}
              >
                <Typography>{hideProjects ? "Hide" : "Expand"} Projects</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Typography variant='h5' component='h4' sx={{ mb: 3 }}>
                    CAR RPPB Projects
                  </Typography>
                  <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                    {carRppbProjects?.map((project, index) => {
                      return (

                        <Box className="biochar-project" key={index}
                          sx={activeCarRppbProject?.id === project.id && { background: 'linear-gradient(to bottom right, #535988, #313551)', color: '#FFF', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                          onClick={() => {
                            setActiveCarRppbProject(project)
                          }}>
                          <Typography sx={{ fontSize: '18px' }}>{activeCarRppbProject?.id === project.id ? <BookmarkOutlinedIcon sx={{ mb: '-7px' }} /> : <BookmarkBorderOutlinedIcon sx={{ mb: '-7px' }} />} {project.id}</Typography>
                          <Typography sx={project.projectName ? { fontSize: '14px' } : { visibility: 'hidden' }} mt={3}><strong>Project Name:</strong> {project.projectName}</Typography>
                          <Typography sx={{ fontSize: '14px' }}><strong>Date Created:</strong> {moment(project.createdAt).format('MM-DD-YY')}</Typography>
                        </Box>
                      )
                    })}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          }

          <BiocharProduction activeCarRppbProject={activeCarRppbProject} activeCarRppb={activeCarRppb} />
          <FeedstockDelivered activeCarRppbProject={activeCarRppbProject} activeCarRppb={activeCarRppb} />
        </>
      )
  }

  return (
    <>
      {renderHomeContent()}
    </>
  );
};

const mapStateToProps = state => ({
  projects: state.postFormData.projects,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getProjects: () => dispatch(getProjects()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);