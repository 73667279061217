import { Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react'
import DownstreamDistributionDataCapturing from './DownstreamDistributionDataCapturing';

export default function DownstreamDistribution() {
    const [selected, setSelected] = useState('')
    let distributionCategories = ['Inter-DC Distribution', 'Farm-DC Distribution', 'Shipping Distribution', 'Regional Distribution']
    const group = localStorage.getItem('group')

    useEffect(() => {
        if (group == 2 || group == 3)
            setSelected('Farm-DC Distribution')
        else
            setSelected('Inter-DC Distribution')
    }, [])

    if (group == 2 || group == 3) {
        distributionCategories = distributionCategories.filter(c => c !== 'Inter-DC Distribution' && c !== 'Shipping Distribution')
    }
    if (group == 1)
        distributionCategories = distributionCategories.filter(c => c !== 'Farm-DC Distribution')

    const outputComponent = () => {
        switch (selected) {
            case 'Inter-DC Distribution':
                return <DownstreamDistributionDataCapturing distributionType={selected} />
            case 'Farm-DC Distribution':
                return <DownstreamDistributionDataCapturing distributionType={selected} />
            case 'Shipping Distribution':
                return <DownstreamDistributionDataCapturing distributionType={selected} />
            case 'Regional Distribution':
                return <DownstreamDistributionDataCapturing distributionType={selected} />
            default:
                return null
        }
    }

    return (
        <>
            <Grid item xs={2}>
                <Box marginTop={2}>
                    {distributionCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catThree', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })}
                </Box>
            </Grid>
            <Grid item xs={10}>
                {outputComponent()}
            </Grid>
        </>
    );
}
