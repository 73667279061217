import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Box, Grid, Typography, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell } from '@mui/material';
import { getBiocharProduction } from '../../../../actions/tigercat-burns/tigercat-dashboard-actions';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

export const BiocharProduction = ({ activeCarRppbProject }) => {

    const dispatch = useDispatch()

    const biocharProduction = useSelector(state => state.tigercatBurnsDashboardReducer.biocharProduction).biocharProductionData
    useEffect(() => {
        if (activeCarRppbProject) {
            dispatch(getBiocharProduction(activeCarRppbProject.id))
        }
    }, [dispatch, activeCarRppbProject])
    const formattedData = biocharProduction?.mbIdWetMasses.map((item) => ({
        ...item,
        avgMoistureContent: item.avgMoistureContent ?? 0,
    }));
    return (
        <>
            {activeCarRppbProject &&
                <Grid container>
                    <Grid item sm={12}>
                        <Typography variant='h5' component='h4' sx={{ my: 2.5 }}>
                            Biochar Production Summary
                        </Typography>
                    </Grid>
                    <Grid item md={6} >
                        {/* ITERATE OVER NOTE LOGS HERE */}
                        {/* {[0, 1, 2, 3, 4, 5].map((v, i) => { */}
                        <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', }} >
                            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Measured From
                                            </TableCell>
                                            <TableCell align="left">
                                                Total Wet Mass
                                            </TableCell>
                                            <TableCell align="left">
                                                Average MC
                                            </TableCell>
                                            <TableCell align="left">
                                                Total Dry Mass
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow >
                                            <TableCell align="left">
                                                Bagged Biochar
                                            </TableCell>
                                            <TableCell align="left">
                                                {biocharProduction?.totalWetMass}
                                            </TableCell>
                                            <TableCell align="left">
                                                {biocharProduction?.avgMoistureContent}
                                            </TableCell>
                                            <TableCell align="left">
                                                {biocharProduction?.totalDryMass}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item md={6} >
                        {console.log(biocharProduction)}
                        {biocharProduction && biocharProduction?.totalWetMass !== 0 && <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={formattedData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
                                <XAxis dataKey="tc_batch__mb_id" label={{ value: "MB ID", position: "insideBottom", dy: 10 }} />
                                <YAxis label={{ value: "Weight / Moisture", angle: -90, position: "insideLeft" }} />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="totalWetMass" fill="#3F5EAB" barSize={50} name="Total Wet Mass" />
                                <Bar dataKey="avgMoistureContent" fill="#3A9D9B" barSize={50} name="Moisture Content" />
                            </BarChart>
                        </ResponsiveContainer>}
                    </Grid>

                </Grid>
            }
        </>
    )
}