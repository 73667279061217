import {
    GET_BIOCHAR_PRODUCTION_SUCCESS,
    GET_BIOCHAR_PRODUCTION_FAIL,
    GET_FEEDSTOCK_DELIVERED_SUCCESS,
    GET_FEEDSTOCK_DELIVERED_FAIL
} from '../../actions/tigercat-burns/types/tigercat-dashboard-types';

const initialState = {
    feedstockDelivered: [],
    biocharProduction: [],
    loading: true
};

export default function tigercatBurnsDashboardReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_BIOCHAR_PRODUCTION_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharProduction: payload
            };
        case GET_BIOCHAR_PRODUCTION_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_FEEDSTOCK_DELIVERED_SUCCESS:
            return {
                ...state,
                loading: false,
                feedstockDelivered: payload
            };
        case GET_FEEDSTOCK_DELIVERED_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}