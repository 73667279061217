import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';

export default function CatThreeSelection({ setSelection, setCatThree }) {

    let catThree = [
        'Business Travel',
        'Downstream Distribution',
        // 'Employee commuting'
    ]
    const facility = localStorage.getItem('facility')



    return (
        <Grid item xs={12}>
            <Typography variant='h5'>Category</Typography>
            <Divider />
            <Alert severity='info'>Please select a category</Alert>
            <Box marginTop={2}>
                {catThree.map((c, i) => {
                    return (
                        <Button
                            key={i}
                            variant='contained'
                            size='large'
                            disabled={c === 'Downstream Distribution' && facility == '5'}
                            sx={{ width: '35%', display: 'block', mb: 2.5 }}
                            onClick={() => {
                                setCatThree(c)
                                setSelection({ id: 7, title: c })
                                localStorage.setItem('catThree', c)
                            }}
                        >
                            {c}
                        </Button>
                    )
                })
                }
            </Box>
        </Grid>
    );
}
