import {
    POST_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS,
    POST_DOWNSTREAM_DISTRIBUTION_DATA_FAIL,
    GET_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS,
    GET_DOWNSTREAM_DISTRIBUTION_DATA_FAIL,
    GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_SUCCESS,
    GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_FAIL,
} from '../../../actions/carbonfootprint-rework/types/03-indirect-ghg-emissions-from-transportation';

const initialState = {
    loading: true,
    downstreamDistributionData: [],
    downstreamDistributionDataTotals: []
};

// flights & accommodation REDUCERS
export default function downstreamDistributionReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case POST_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_DOWNSTREAM_DISTRIBUTION_DATA_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS:
            return {
                ...state,
                downstreamDistributionData: payload,
                loading: false,
            };
        case GET_DOWNSTREAM_DISTRIBUTION_DATA_FAIL:
            return {
                ...state,
                downstreamDistributionData: payload,
                loading: false
            }
        case GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_SUCCESS:
            return {
                ...state,
                downstreamDistributionDataTotals: payload,
                loading: false,
            };
        case GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_FAIL:
            return {
                ...state,
                downstreamDistributionDataTotals: payload,
                loading: false
            }

        default:
            return state;
    }
}