export const API_POST_BASE_LOG_KILN = 'api/log-kiln'
export const API_GET_BASE_LOG_KILNS = 'api/get-log-kilns'
export const API_GET_PROJECT_BATCHES = 'api/batches'
export const API_GET_ROF_BURNS_BATCH_DATA = 'api/rof-burns/batch-data'
export const API_GET_ROF_BURNS_BATCH_MEDIA = 'api/rof-burns/batch-media'
export const API_GET_ROF_BURNS_ADDITIONAL_BATCH_DATA = 'api/rof-burns/additional-batch-data'
export const API_GET_ROF_BURNS_BATCH_VOLUMES = 'api/rof-burns/batch-volumes'
export const API_GET_ROF_BURNS_BATCH_BULK_DENSITY = 'api/rof-burns/batch-bulk-density'
export const API_GET_ROF_BURNS_BIOCHAR_MASS = 'api/rof-burns/biochar-mass'
export const API_GET_ROF_BURNS_BATCH_CARBON_CONTENT = 'api/rof-burns/batch-carbon-content'
export const API_GET_ROF_BURNS_BIOCHAR_STORED_CARBON = 'api/rof-burns/biochar-stored-carbon'
export const API_POST_ROF_BURNS_BATCH_CCIK = 'api/rof-burns/batch-ccik'
export const API_POST_ROF_BURNS_BIOCHAR_STORED_CARBON = 'api/rof-burns/biochar-stored-carbon-prdeb'
export const API_POST_ROF_BURNS_LAB_REPORT_FILE = 'api/rof-burns/lab-report-file-upload'
export const API_GET_ROF_BURNS_LAB_REPORT_FILES = 'api/rof-burns/lab-report-file-uploads'
export const API_GET_DOWNLOAD_BATCH_REPORT = 'api/rof-burns/download-batch-report'