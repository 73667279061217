// POST FLIGHTS & ACCOMMODATION SUCCESS & FAIL
export const POST_FLIGHTS_AND_ACCOMMODATION_DATA_SUCCESS = 'POST_FLIGHTS_AND_ACCOMMODATION_DATA_SUCCESS';
export const POST_FLIGHTS_AND_ACCOMMODATION_DATA_FAIL = 'POST_FLIGHTS_AND_ACCOMMODATION_DATA_FAIL';

// GET FLIGHTS & ACCOMMODATION SUCCESS & FAIL
export const GET_FLIGHTS_AND_ACCOMMODATION_DATA_SUCCESS = 'GET_FLIGHTS_AND_ACCOMMODATION_DATA_SUCCESS';
export const GET_FLIGHTS_AND_ACCOMMODATION_DATA_FAIL = 'GET_FLIGHTS_AND_ACCOMMODATION_DATA_FAIL';

// GET FLIGHTS & ACCOMMODATION TOTALS
export const GET_FLIGHTS_AND_ACCOMMODATION_DATA_TOTALS_SUCCESS = 'GET_FLIGHTS_AND_ACCOMMODATION_DATA_TOTALS_SUCCESS';
export const GET_FLIGHTS_AND_ACCOMMODATION_DATA_TOTALS_FAIL = 'GET_FLIGHTS_AND_ACCOMMODATION_DATA_TOTALS_FAIL';

// POST CAR HIRE SUCCESS & FAIL
export const POST_CAR_HIRE_DATA_SUCCESS = 'POST_CAR_HIRE_DATA_SUCCESS';
export const POST_CAR_HIRE_DATA_FAIL = 'POST_CAR_HIRE_DATA_FAIL';

// GET CAR HIRE SUCCESS & FAIL
export const GET_CAR_HIRE_DATA_SUCCESS = 'GET_CAR_HIRE_DATA_SUCCESS';
export const GET_CAR_HIRE_DATA_FAIL = 'GET_CAR_HIRE_DATA_FAIL';

// GET CAR HIRE TOTALS
export const GET_CAR_HIRE_DATA_TOTALS_SUCCESS = 'GET_CAR_HIRE_DATA_TOTALS_SUCCESS';
export const GET_CAR_HIRE_DATA_TOTALS_FAIL = 'GET_CAR_HIRE_DATA_TOTALS_FAIL';

// GET_PETROL_CARD_HOLDERS
export const GET_PETROL_CARD_HOLDERS_SUCCESS = 'GET_PETROL_CARD_HOLDERS_SUCCESS';
export const GET_PETROL_CARD_HOLDERS_FAIL = 'GET_PETROL_CARD_HOLDERS_FAIL';
// POST_PETROL_CARD_HOLDER
export const POST_PETROL_CARD_HOLDER_SUCCESS = 'POST_PETROL_CARD_HOLDER_SUCCESS';
export const POST_PETROL_CARD_HOLDER_FAIL = 'POST_PETROL_CARD_HOLDER_FAIL';
// GET_PETROL_CARDS_DATA SUCCESS & FAIL
export const GET_PETROL_CARDS_DATA_SUCCESS = 'GET_PETROL_CARDS_DATA_SUCCESS';
export const GET_PETROL_CARDS_DATA_FAIL = 'GET_PETROL_CARDS_DATA_FAIL';
// POST_PETROL_CARDS_DATA SUCCESS & FAIL
export const POST_PETROL_CARDS_DATA_SUCCESS = 'POST_PETROL_CARDS_DATA_SUCCESS';
export const POST_PETROL_CARDS_DATA_FAIL = 'POST_PETROL_CARDS_DATA_FAIL';
// GET_PETROL_CARDS_DATA_TOTALS SUCCESS & FAIL
export const GET_PETROL_CARDS_DATA_TOTALS_SUCCESS = 'GET_PETROL_CARDS_DATA_TOTALS_SUCCESS';
export const GET_PETROL_CARDS_DATA_TOTALS_FAIL = 'GET_PETROL_CARDS_DATA_TOTALS_FAIL';
// POST DOWNSTREAM DISTRIBUTION SUCCESS & FAIL
export const POST_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS = 'POST_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS';
export const POST_DOWNSTREAM_DISTRIBUTION_DATA_FAIL = 'POST_DOWNSTREAM_DISTRIBUTION_DATA_FAIL';
// GET DOWNSTREAM DISTRIBUTION SUCCESS & FAIL
export const GET_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS = 'GET_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS';
export const GET_DOWNSTREAM_DISTRIBUTION_DATA_FAIL = 'GET_DOWNSTREAM_DISTRIBUTION_DATA_FAIL';
// GET DOWNSTREAM DISTRIBUTION TOTALS SUCCESS & FAIL
export const GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_SUCCESS = 'GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_SUCCESS';
export const GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_FAIL = 'GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_FAIL';