import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRofBurnsBatchMedia } from '../../../../../../actions/rof-burns/rof-actions'
import { Alert, Box, Divider, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RofMedia = ({ projectKey }) => {
    const dispatch = useDispatch()
    const rofMedia = useSelector(state => state.rofBurnsReducer.batchMedia)?.data
    const [hideState, setHideState] = useState({})

    useEffect(() => {
        dispatch(getRofBurnsBatchMedia(projectKey))
    }, [dispatch, projectKey])

    const toggleHide = (batchId) => {
        setHideState(prevState => ({
            ...prevState,
            [batchId]: !prevState[batchId]
        }))
    }

    const renderPhotos = (photos, title) => (
        <>
            <h3>{title}</h3>
            {!photos.length ? (
                <Alert severity="info">No {title} media</Alert>
            ) : (
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '16px', mb: 5 }}>
                    {photos.map((photo, index) => (
                        <img key={index} src={photo} alt={`${title} Photo ${index}`} width="300" />
                    ))}
                </Box>
            )}
        </>
    )

    return (
        <Box>
            <h2>Ring of Fire Batch Media</h2>
            {rofMedia && rofMedia.map((batch, index) => {
                const hasNoRingOfFireMedia =
                    !batch.rofFeedstockPhotos.length &&
                    !batch.rofMoistureContentPhotos.length &&
                    !batch.rofBurnStartPhotos.length &&
                    !batch.rofBulkDensityPhotos.length &&
                    !batch.rofKilnDepthPhotos.length &&
                    !batch.rofLogUnburntVolumePhotos.length

                return (
                    <Box key={index}>
                        {hasNoRingOfFireMedia && (
                            <Alert severity="info" sx={{ my: 3 }}>No Ring of Fire Media for Batch: {batch.batchId}</Alert>
                        )}
                        {!hasNoRingOfFireMedia && (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => toggleHide(`ROF - ${batch.batchId}`)}
                                >
                                    <Typography>{hideState[`ROF - ${batch.batchId}`] ? "Hide" : "Expand"} {batch.batchId}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h3>Ring of Fire Media for Batch: {batch.batchId}</h3>
                                    {renderPhotos(batch.rofFeedstockPhotos, 'Dry Feedstock')}
                                    {renderPhotos(batch.rofMoistureContentPhotos, 'Moisture Content')}
                                    {renderPhotos(batch.rofBurnStartPhotos, 'Burn Start')}
                                    {renderPhotos(batch.rofBulkDensityPhotos, 'Bulk Density')}
                                    {renderPhotos(batch.rofKilnDepthPhotos, 'Kiln Depth')}
                                    {renderPhotos(batch.rofLogUnburntVolumePhotos, 'Log Unburnt Volume')}
                                    <Divider />
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                )
            })}
            <h2>Conservation Burns Batch Media</h2>
            {rofMedia && rofMedia.map((batch, index) => {
                const hasNoConservationBurnMedia =
                    !batch.cbMoistureContentPhotos.length &&
                    !batch.cbLogPileSizePhotos.length &&
                    !batch.cbLogFeedstockPileCountPhotos.length &&
                    !batch.cbBulkDensityPhotos.length &&
                    !batch.cbVolumeMeasurementPhotos.length &&
                    !batch.cbLogBiocharPileCountPhotos.length

                return (
                    <Box key={index}>
                        {hasNoConservationBurnMedia && (
                            <Alert severity="info" sx={{ my: 3 }}>No Conservation Burn Media for Batch: {batch.batchId}</Alert>
                        )}
                        {!hasNoConservationBurnMedia && (

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => toggleHide(`CB - ${batch.batchId}`)}
                                >
                                    <Typography>{hideState[`CB - ${batch.batchId}`] ? "Hide" : "Expand"} {batch.batchId}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h3>Conservation Burn Media for Batch: {batch.batchId}</h3>
                                    {renderPhotos(batch.cbMoistureContentPhotos, 'Moisture Content')}
                                    {renderPhotos(batch.cbLogPileSizePhotos, 'Log Pile Size')}
                                    {renderPhotos(batch.cbLogFeedstockPileCountPhotos, 'Log Feedstock Pile Count')}
                                    {renderPhotos(batch.cbBulkDensityPhotos, 'Bulk Density')}
                                    {renderPhotos(batch.cbVolumeMeasurementPhotos, 'Volume Measurement')}
                                    {renderPhotos(batch.cbLogBiocharPileCountPhotos, 'Log Biochar Pile Count')}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                )
            })}
        </Box>
    )
}

export default RofMedia