import {
    ADD_INTENSITY_METRIC_SUCCESS,
    ADD_INTENSITY_METRIC_FAIL,
    GET_INTENSITY_METRICS_SUCCESS,
    GET_INTENSITY_METRICS_FAIL
} from '../../../actions/carbonfootprint-rework/types/01-direct-ghg-emissions-and-removals-types';


const initialState = {
    loading: true,
    intensityMetricData: []
};

// PETROL REDUCERS
export default function
    directGhgEmissionsAndRemovalsForIntensityMetrics(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case ADD_INTENSITY_METRIC_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_INTENSITY_METRIC_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_INTENSITY_METRICS_SUCCESS:
            return {
                ...state,
                loading: false,
                intensityMetricData: payload
            };
        case GET_INTENSITY_METRICS_FAIL:
            return {
                ...state,
                loading: false,
                intensityMetricData: payload
            }
        default:
            return state;
    }
}


