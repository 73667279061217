import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react'
import EmployeeCommutingDataCapturing from './EmployeeCommutingDataCapturing';

export default function EmployeeCommuting() {
    const [selected, setSelected] = useState('Distribution Centre')
    let commuteCategories = ['Distribution Centre', 'Regional Office', 'Head Office', 'Other']
    const group = localStorage.getItem('group')
    if (group !== '1')
        commuteCategories = null
    // const handleAssetPetrolAssetSelection = () => {

    // }
    // const handlePetrolUnitSelection = () => {

    // }

    // const handlePetrolInputChange = () => {

    // }

    return (
        <>
            {!!commuteCategories &&
                <Grid item xs={3}>
                    <Box marginTop={2}>
                        {commuteCategories.map((c, i) => {
                            return (
                                <Button
                                    key={i}
                                    variant={c === selected ? 'contained' : 'outlined'}
                                    size='large'
                                    sx={{ width: '100%', mb: 2.5 }}
                                    onClick={() => {
                                        setSelected(c)
                                        localStorage.setItem('catOne', c)
                                    }}
                                >
                                    {c}
                                </Button>
                            )
                        })
                        }
                    </Box>
                </Grid>
            }
            <Grid item xs={!!commuteCategories ? 9 : 12}>
                <EmployeeCommutingDataCapturing selected={selected} />
            </Grid>
        </>
    );
}

/*
Select Category 1->Select Petrol->Select Petrol (mobile) ->
Select month ->Select asset from drop down list->
Select unit from drop down list (rand or Litres)->
Manually Enter petrol mobile consumption for the selected asset in the selected month with the corresponding unit->
Upload corresponding source documentation

Users should be able to see the annual sum table for corresponding line items in the TOTALS tab (i.e. 12 month total).

*/
