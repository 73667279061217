import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Box, Grid, Typography, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Button } from '@mui/material';
import { getFeedstockDelivered } from '../../../../actions/tigercat-burns/tigercat-dashboard-actions';
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from "recharts";
import { useNavigate } from 'react-router-dom';
export const FeedstockDelivered = ({ activeCarRppb, activeCarRppbProject }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const feedstockDelivered = useSelector(state => state.tigercatBurnsDashboardReducer.feedstockDelivered).feedstockDeliveredData
    // console.log(feedstockDelivered)
    useEffect(() => {
        if (activeCarRppbProject) {
            dispatch(getFeedstockDelivered(activeCarRppbProject.id))
        }
    }, [dispatch, activeCarRppbProject])

    const COLORS = ["#3F5EAB", "#3A9D9B", "#ffbb28", "#F4A259"];
    // const formattedData = biocharProduction?.mbIdWetMasses.map((item) => ({
    //     ...item,
    //     avgMoistureContent: item.avgMoistureContent ?? 0,
    // }));
    const handleNavigateFeedstock = (carRppb, carRppbProject) => {
        navigate('/car-reporting', {
            state: { carRppb, carRppbProject }
        })
    }
    return (
        <>
            {activeCarRppbProject &&
                <Grid container>
                    <Grid item sm={12}>
                        <Typography variant='h5' component='h4' sx={{ my: 2.5 }}>
                            Feedstock Delivered Summary
                        </Typography>
                    </Grid>
                    <Grid item md={6} >
                        {/* ITERATE OVER NOTE LOGS HERE */}
                        {/* {[0, 1, 2, 3, 4, 5].map((v, i) => { */}
                        <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', }} >
                            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Delivered By
                                            </TableCell>
                                            <TableCell align="left">
                                                Total Mass Delivered
                                            </TableCell>
                                            <TableCell align="left">
                                                Detailed Feedstock
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {feedstockDelivered?.map((entry, index) => {
                                            return (
                                                <TableRow key={index} >
                                                    <TableCell align="left">
                                                        {entry?.delivered_by}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {entry?.totalMassDelivered}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Button
                                                            // sx={{ mr: 2 }}
                                                            variant={'contained'}
                                                            color='primary'
                                                            onClick={() => handleNavigateFeedstock(activeCarRppb, activeCarRppbProject)}>
                                                            Feedstock Transport
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item md={6} >
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Pie
                                    data={feedstockDelivered}
                                    dataKey="totalMassDelivered"
                                    nameKey="delivered_by"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={120}
                                    fill="#8884d8"
                                    label
                                >
                                    {feedstockDelivered?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Grid>
                </Grid>
            }
        </>
    )
}