import { setAlert } from '../../alert';
import {
    API_POST_ADD_EMPLOYEE_COMMUTING,
    API_GET_EMPLOYEE_COMMUTINGS,
    API_POST_ADD_EMPLOYEE_COMMUTING_SURVEY_RESPONSE_RATE,
    API_GET_EMPLOYEE_COMMUTING_SURVEY_RESPONSE_RATES,
} from '../constants/01-direct-ghg-emissions-and-removals-constants';
import {
    POST_ADD_EMPLOYEE_COMMUTING_SUCCESS,
    POST_ADD_EMPLOYEE_COMMUTING_FAIL,
    GET_EMPLOYEE_COMMUTING_SUCCESS,
    GET_EMPLOYEE_COMMUTING_FAIL,
    POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS,
    POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL,
    GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS,
    GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL
} from '../types/01-direct-ghg-emissions-and-removals-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};

export const addEmployeeCommuting = (formData) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_ADD_EMPLOYEE_COMMUTING,
            formData,
            config
        );
        dispatch({
            type: POST_ADD_EMPLOYEE_COMMUTING_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_ADD_EMPLOYEE_COMMUTING_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getEmployeeCommuting = (group, year, location) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_EMPLOYEE_COMMUTINGS + `/${group}` + `/${year}` + `/${location}`,
            config
        );
        dispatch({
            type: GET_EMPLOYEE_COMMUTING_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_EMPLOYEE_COMMUTING_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const addEmployeeCommutingResponseRate = (formData) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_ADD_EMPLOYEE_COMMUTING_SURVEY_RESPONSE_RATE,
            formData,
            config
        );
        dispatch({
            type: POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getEmployeeCommutingResponseRates = (group, year, location) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_EMPLOYEE_COMMUTING_SURVEY_RESPONSE_RATES + `/${group}` + `/${year}` + `/${location}`,
            config
        );
        dispatch({
            type: GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL,
            payload: err.response.data.detail,
        });
    }
};

