// 01 DIESEL
// 02 PETROL
// 03 LPG
// 04 REFRIGERANT GAS
// 05 DRY ICE
// 06 COMPOST APPLIED
// 07 FERTILIZER APPLIED

/*
STATIONARY & MOBILE DIESEL API CALLS
*/

// POST STATIONARY DIESEL SUCCESS & FAIL
export const POST_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS = 'POST_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS';
export const POST_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL = 'POST_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL';

// GET STATIONARY DIESEL SUCCESS & FAIL
export const GET_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS = 'GET_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS';
export const GET_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL = 'GET_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL';

// GET STATIONARY DIESEL TOTALS SUCCESS & FAIL
export const GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_SUCCESS = 'GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_SUCCESS';
export const GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_FAIL = 'GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_FAIL';

/*
STATIONARY & MOBILE PETROL API CALLS
*/

// POST STATIONARY PETROL SUCCESS & FAIL
export const POST_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS = 'POST_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS';
export const POST_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL = 'POST_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL';

// GET STATIONARY PETROL SUCCESS & FAIL
export const GET_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS = 'GET_STATIONARY_OR_MOBILE_PETROL_DATA_SUCCESS';
export const GET_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL = 'GET_STATIONARY_OR_MOBILE_PETROL_DATA_FAIL';

// GET STATIONARY PETROL TOTALS SUCCESS & FAIL
export const GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_SUCCESS = 'GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_SUCCESS';
export const GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_FAIL = 'GET_STATIONARY_OR_MOBILE_PETROL_DATA_TOTALS_FAIL';

// GET HIGHEST ID
export const GET_HIGHEST_ID_SUCCESS = 'GET_HIGHEST_ID_SUCCESS';
export const GET_HIGHEST_ID_FAIL = 'GET_HIGHEST_ID_FAIL';

/*
DRY ICE API CALLS
*/

// POST DRY ICE SUCCESS & FAIL
export const POST_DRY_ICE_DATA_SUCCESS = 'POST_DRY_ICE_DATA_SUCCESS';
export const POST_DRY_ICE_DATA_FAIL = 'POST_DRY_ICE_DATA_FAIL';

// GET DRY ICE SUCCESS & FAIL
export const GET_DRY_ICE_DATA_SUCCESS = 'GET_DRY_ICE_DATA_SUCCESS';
export const GET_DRY_ICE_DATA_FAIL = 'GET_DRY_ICE_DATA_FAIL';

// GET DRY ICE TOTALS
export const GET_DRY_ICE_DATA_TOTALS_SUCCESS = 'GET_DRY_ICE_DATA_TOTALS_SUCCESS';
export const GET_DRY_ICE_DATA_TOTALS_FAIL = 'GET_DRY_ICE_DATA_TOTALS_FAIL';

/*
LPG API CALLS
*/

export const POST_LPG_DATA_SUCCESS = 'POST_LPG_DATA_SUCCESS';
export const POST_LPG_DATA_FAIL = 'POST_LPG_DATA_FAIL';

export const GET_LPG_DATA_SUCCESS = 'GET_LPG_DATA_SUCCESS';
export const GET_LPG_DATA_FAIL = 'GET_LPG_DATA_FAIL';

export const GET_LPG_DATA_TOTALS_SUCCESS = 'GET_STATIONARY_PETROL_DATA_SUCCESS';
export const GET_LPG_DATA_TOTALS_FAIL = 'GET_STATIONARY_PETROL_DATA_FAIL';

/*
REFRIGERANT GAS API CALLS
*/

export const POST_REFRIGERANT_GAS_DATA_SUCCESS = 'POST_REFRIGERANT_GAS_DATA_SUCCESS';
export const POST_REFRIGERANT_GAS_DATA_FAIL = 'POST_REFRIGERANT_GAS_DATA_FAIL';

export const GET_REFRIGERANT_GAS_DATA_SUCCESS = 'GET_REFRIGERANT_GAS_DATA_SUCCESS';
export const GET_REFRIGERANT_GAS_DATA_FAIL = 'GET_REFRIGERANT_GAS_DATA_FAIL';

export const GET_REFRIGERANT_GAS_DATA_TOTALS_SUCCESS = 'GET_REFRIGERANT_GAS_DATA_TOTALS_SUCCESS';
export const GET_REFRIGERANT_GAS_DATA_TOTALS_FAIL = 'GET_REFRIGERANT_GAS_DATA_TOTALS_FAIL';

/*
ADD ASSET API CALLS
*/

// ADD ASSET
export const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
export const ADD_ASSET_FAIL = 'ADD_ASSET_FAIL';

// GET ASSETS
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_FAIL = 'GET_ASSETS_FAIL'

// ADD INTENSITY METRIC
export const ADD_INTENSITY_METRIC_SUCCESS = 'ADD_INTENSITY METRIC_SUCCESS';
export const ADD_INTENSITY_METRIC_FAIL = 'ADD_INTENSITY METRIC_FAIL';

// GET INTENSITY METRICS
export const GET_INTENSITY_METRICS_SUCCESS = 'GET_INTENSITY METRICS_SUCCESS';
export const GET_INTENSITY_METRICS_FAIL = 'GET_INTENSITY METRICS_FAIL'

// EMPLOYEE COMMUTING
export const POST_ADD_EMPLOYEE_COMMUTING_SUCCESS = 'POST_ADD_EMPLOYEE_COMMUTING_SUCCESS';
export const POST_ADD_EMPLOYEE_COMMUTING_FAIL = 'POST_ADD_EMPLOYEE_COMMUTING_FAIL';
export const GET_EMPLOYEE_COMMUTING_SUCCESS = 'GET_EMPLOYEE_COMMUTING_SUCCESS';
export const GET_EMPLOYEE_COMMUTING_FAIL = 'GET_EMPLOYEE_COMMUTING_FAIL';

export const POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS = 'POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS';
export const POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL = 'POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL';
export const GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS = 'GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS';
export const GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL = 'GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL';


// FILE UPLOAD
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';


