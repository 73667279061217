import {
    POST_ADD_EMPLOYEE_COMMUTING_SUCCESS,
    POST_ADD_EMPLOYEE_COMMUTING_FAIL,
    GET_EMPLOYEE_COMMUTING_SUCCESS,
    GET_EMPLOYEE_COMMUTING_FAIL,
    POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS,
    POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL,
    GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS,
    GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL
} from '../../../actions/carbonfootprint-rework/types/01-direct-ghg-emissions-and-removals-types';


const initialState = {
    loading: true,
    employeeCommutingData: [],
    employeeCommutingResponseRateData: []
};

// PETROL REDUCERS
export default function
    directGhgEmissionsAndRemovalsForEmployeeCommuting(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case POST_ADD_EMPLOYEE_COMMUTING_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_ADD_EMPLOYEE_COMMUTING_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_EMPLOYEE_COMMUTING_SUCCESS:
            return {
                ...state,
                loading: false,
                employeeCommutingData: payload
            };
        case GET_EMPLOYEE_COMMUTING_FAIL:
            return {
                ...state,
                loading: false,
                employeeCommutingData: payload
            }
        case POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_ADD_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
                employeeCommutingResponseRateData: payload
            };
        case GET_EMPLOYEE_COMMUTING_RESPONSE_RATE_FAIL:
            return {
                ...state,
                loading: false,
                employeeCommutingResponseRateData: payload
            }
        default:
            return state;
    }
}


