import { useEffect, useState } from 'react';
import {
    Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Tabs, Tab, Select, MenuItem, FormControl, InputLabel, Button, TextField
} from '@mui/material';
import { getDownstreamDistributionData, getDownstreamDistributionDataTotals, postDownstreamDistributionData } from '../../../../../actions/carbonfootprint-rework/03-indirect-ghg-emissions-from-transportation/04-downstream-distribution-actions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { directGhgEnmissionsAndRemovalsFileUpload } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/00-file-upload';
import { geHighestId } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/01-diesel-actions';
import DownstreamDistributionTotalTable from './DownstreamDistributionTotalTable';

const DownstreamDistributionDataCapturing = ({ distributionType }) => {
    const dispatch = useDispatch()

    const downstreamDistributionData = useSelector((state) => state.downstreamDistributionReducer.downstreamDistributionData?.data)
    const downstreamDistributionDataTotals = useSelector((state) => state.downstreamDistributionReducer.downstreamDistributionDataTotals?.data)
    let highestId = useSelector((state) => state.directGhgEmissionsAndRemovalsForDiesel.highestId.max_id)

    const year = localStorage.getItem('year')
    const facility = localStorage.getItem('facility')
    const group = localStorage.getItem('group')
    const company = localStorage.getItem('company')

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Totals']
    const [month, setMonth] = useState(0);
    const [assetRows, setAssetRows] = useState({ ...downstreamDistributionData })

    useEffect(() => {
        dispatch(geHighestId('downstream-distribution'))
        dispatch(getDownstreamDistributionData(year, facility, distributionType))
        dispatch(getDownstreamDistributionDataTotals(year, facility, distributionType))
    }, [dispatch, year, facility, distributionType])

    useEffect(() => {
        if (downstreamDistributionData && downstreamDistributionData[month]?.length > 0) setAssetRows({ ...downstreamDistributionData })
        else setAssetRows({ 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [] })
    }, [downstreamDistributionData, dispatch, month])

    const handleMonthChange = (event, newValue) => {
        setMonth(newValue);
        if (newValue === 12) {
            dispatch(getDownstreamDistributionDataTotals(year, facility, distributionType))
        } else {
            dispatch(getDownstreamDistributionData(year, facility, distributionType))
            dispatch(geHighestId('downstream-distribution'))
        }
    };

    const handleAddAsset = async () => {
        await dispatch(geHighestId('downstream-distribution')).then(() => {
            setAssetRows((prevRows) => {
                let highestIdInAssetRows = 0;
                for (let key in assetRows) {
                    let sublist = assetRows[key];
                    for (let item of sublist) {
                        highestIdInAssetRows = Math.max(highestId, item.id);
                    }
                }
                if (highestIdInAssetRows >= highestId) {
                    highestId = ++highestIdInAssetRows;
                } else {
                    highestId = ++highestId
                }
                const prevRowsMonth = [...prevRows[month], { id: highestId, flightType: '', emissions: 'kg CO2', quantity: '', numberOfTravellers: '', hotelStays: '', file: '' }]
                prevRows[month] = prevRowsMonth
                return { ...prevRows }
            })
        })
    }

    const transportTypes = [
        { id: 0, value: "Vans (petrol), Class I (up to 1.305 tonnes)" },
        { id: 1, value: "Vans (petrol), Class II (1.305 to 1.74 tonnes)" },
        { id: 2, value: "Vans (petrol), Class III (1.74 to 3.5 tonnes)" },
        { id: 3, value: "Vans (diesel), Class I (up to 1.305 tonnes)" },
        { id: 4, value: "Vans (diesel), Class II (1.305 to 1.74 tonnes)" },
        { id: 5, value: "Vans (diesel), Class III (1.74 to 3.5 tonnes)" },
        { id: 6, value: "HGV (all diesel), Rigid (>3.5 - 7.5 tonnes)" },
        { id: 7, value: "HGV (all diesel), Rigid (>7.5 tonnes-17 tonnes)" },
        { id: 8, value: "HGV (all diesel), Rigid (>17 tonnes)" },
        { id: 9, value: "HGV (all diesel), Articulated (>3.5 - 33t)" },
        { id: 10, value: "HGV (all diesel), Articulated (>33t)" },
        { id: 11, value: "HGV refrigerated (all diesel), Rigid (>3.5 - 7.5 tonnes)" },
        { id: 12, value: "HGV refrigerated (all diesel), Rigid (>7.5 tonnes-17 tonnes)" },
        { id: 13, value: "HGV refrigerated (all diesel), Rigid (>17 tonnes)" },
        { id: 14, value: "HGV refrigerated (all diesel), Articulated (>3.5 - 33t)" },
        { id: 15, value: "HGV refrigerated (all diesel), Articulated (>33t)" },
        { id: 16, value: "Freight flights, Domestic" },
        { id: 17, value: "Freight flights, International" },
        { id: 18, value: "Rail, Freight train" },
        { id: 19, value: "Cargo ship, Bulk carrier" },
        { id: 20, value: "Cargo ship, General cargo" },
        { id: 21, value: "Cargo ship, Container ship" }
    ];

    const destinations = [
        { id: 0, value: "JHB" },
        { id: 1, value: "JHB - PNP DC Eastport" },
        { id: 2, value: "JHB - Woolworths Midrand" },
        { id: 3, value: "JHB - Spar South Rand" },
        { id: 4, value: "KZN" },
        { id: 5, value: "Nelspruit" },
        { id: 6, value: "Bloemfontein" },
        { id: 7, value: "Gqeberha" },
        { id: 8, value: "East London" },
        { id: 9, value: "George" }
    ];

    const handleTransportTypeSelection = (e, month, id) => {
        setAssetRows((prevAssets) => {
            const assetToEdit = prevAssets[month].find((data) => data.id === id)
            assetToEdit['transportType'] = e.target.value
            const filteredMonth = prevAssets[month].filter((d) => d.id !== id)
            prevAssets[month] = [...filteredMonth, assetToEdit].sort((a, b) => a.id - b.id)
            return { ...prevAssets }
        })
    }


    const handleDestinationSelection = (e, month, id) => {
        setAssetRows((prevUnits) => {
            const emissionToEdit = prevUnits[month].find((data) => data.id === id)
            emissionToEdit['destination'] = e.target.value
            const filteredMonth = prevUnits[month].filter((d) => d.id !== id)
            prevUnits[month] = [...filteredMonth, emissionToEdit].sort((a, b) => a.id - b.id)
            return { ...prevUnits }
        })
    }
    // const handlEmissionSelection = (e, month, id) => {
    //     setAssetRows((prevUnits) => {
    //         const emissionToEdit = prevUnits[month].find((data) => data.id === id)
    //         emissionToEdit['emissions'] = e.target.value
    //         const filteredMonth = prevUnits[month].filter((d) => d.id !== id)
    //         prevUnits[month] = [...filteredMonth, emissionToEdit].sort((a, b) => a.id - b.id)
    //         return { ...prevUnits }
    //     })
    // }

    // const handleHotelStaysSelection = (e, month, id) => {
    //     setAssetRows((prevUnits) => {
    //         const emissionToEdit = prevUnits[month].find((data) => data.id === id)
    //         emissionToEdit['hotelStays'] = e.target.value
    //         const filteredMonth = prevUnits[month].filter((d) => d.id !== id)
    //         prevUnits[month] = [...filteredMonth, emissionToEdit].sort((a, b) => a.id - b.id)
    //         return { ...prevUnits }
    //     })
    // }

    const handleInputChange = (e, month, id) => {
        setAssetRows((prevRows) => {
            const valToEdit = prevRows[month].find((data) => data.id === id)
            valToEdit[e.target.name] = e.target.value
            const filteredMonth = prevRows[month].filter((d) => d.id !== id)
            prevRows[month] = [...filteredMonth, valToEdit].sort((a, b) => a.id - b.id)
            return { ...prevRows }
        })
    }

    const handleFileUpload = (e, month, id) => {
        setAssetRows((prevRows) => {
            const fileToEdit = prevRows[month].find((data) => data.id === id)
            fileToEdit['file'] = e.target.files[0]
            const filteredMonth = prevRows[month].filter((d) => d.id !== id)
            prevRows[month] = [...filteredMonth, fileToEdit].sort((a, b) => a.id - b.id)
            return { ...prevRows }
        })
    }

    const handleDownstreamDistributionSubmission = () => {
        const removeEmptyMonthValues = (obj) => {
            for (let key in obj) {
                obj[key] = obj[key].filter(item => item.distributionLength !== '' && item.distributionLoadWeight !== '');
                if (obj[key].length === 0) {
                    delete obj[key];
                }
            }
            return obj;
        }
        const clonedAssetRows = removeEmptyMonthValues({ ...assetRows })

        let formData = new FormData();

        Object.keys(clonedAssetRows).forEach((key) => {
            clonedAssetRows[key].forEach(item => {
                if (item.file?.name) {
                    formData.append(item.id, item.file);
                }
            });
        });
        // console.log(clonedAssetRows)
        dispatch(postDownstreamDistributionData(clonedAssetRows, year, company, group, facility, distributionType)).then(() => {
            dispatch(directGhgEnmissionsAndRemovalsFileUpload(formData, 'downstream-distribution'))
        })
    }

    return (
        <>
            <Tabs
                value={month}
                onChange={handleMonthChange}
                sx={{ width: '100%', float: 'left' }}
                textColor='inherit'
                indicatorColor='secondary'
            >
                {months.map((m, i) => {
                    return (
                        <Tab disabled={m === 'Totals'} key={i} label={m} sx={{ minWidth: '25px' }} />
                    )
                })}
            </Tabs>
            {month === 12 ? <DownstreamDistributionTotalTable downstreamDistributionDataTotals={downstreamDistributionDataTotals} /> :
                <>
                    <TableContainer component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Transport Company/Driver
                                    </TableCell>
                                    {/* <TableCell align="left">
                                        Emissions
                                    </TableCell> */}
                                    <TableCell align="left">
                                        Transport Type
                                    </TableCell>
                                    <TableCell align="left">
                                        Destination
                                    </TableCell>
                                    <TableCell align="left">
                                        Distribution Length (km)
                                    </TableCell>
                                    <TableCell align="left">
                                        Distribution Load Weight (tonnes)
                                    </TableCell>
                                    <TableCell align="left">
                                        Soure Document
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assetRows[month]?.map((r, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="left">
                                                <TextField
                                                    required={true}
                                                    fullWidth
                                                    name={'companyOrDriver'}
                                                    value={!!r.companyOrDriver ? r.companyOrDriver : ''}
                                                    onChange={(e) => handleInputChange(e, month, r.id)}
                                                    type="text"
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: '200px' }} variant="outlined" required>
                                                    <InputLabel id="transport-type-label">Select Type</InputLabel>
                                                    <Select
                                                        labelId="transport-type-label" // Linking the label to the select
                                                        name="Type"
                                                        value={r.transportType || ''}
                                                        onChange={(e) => handleTransportTypeSelection(e, month, r.id)}
                                                        label="Select Type" // This should match the InputLabel text
                                                    >
                                                        {transportTypes.map((item) => (
                                                            <MenuItem key={item.id} value={item.value}>
                                                                {item.value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                {group == '1' && distributionType === 'Inter-DC Distribution' ? <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="destination">{'Destination'}</InputLabel>
                                                    <Select
                                                        required={true}
                                                        name="destination"
                                                        value={!!r.destination ? r.destination : ''}
                                                        onChange={(e) => handleDestinationSelection(e, month, r.id)}
                                                        label='Destination'
                                                    >
                                                        {destinations.map((item) => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.value}>
                                                                    {item.value}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl> :
                                                    <TextField
                                                        required={true}
                                                        fullWidth
                                                        name={'destination'}
                                                        value={!!r.destination ? r.destination : ''}
                                                        onChange={(e) => handleInputChange(e, month, r.id)}
                                                        type="text"
                                                    />}
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    required={true}
                                                    fullWidth
                                                    name={'distributionLength'}
                                                    value={!!r.distributionLength ? r.distributionLength : ''}
                                                    onChange={(e) => handleInputChange(e, month, r.id)}
                                                    type="text"
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    required={true}
                                                    fullWidth
                                                    name={'distributionLoadWeight'}
                                                    value={!!r.distributionLoadWeight ? r.distributionLoadWeight : ''}
                                                    onChange={(e) => handleInputChange(e, month, r.id)}
                                                    type="text"
                                                />
                                            </TableCell>


                                            <TableCell align="left">
                                                {r?.file ? <>
                                                    <TextField
                                                        disabled
                                                        name={'Source document'}
                                                        value={!!r?.file?.name ? r.file.name : !!r?.file ? r.file : ''}
                                                        type='text'
                                                    />
                                                    <input
                                                        style={{ display: 'none' }}
                                                        name={'Source document'}
                                                        onChange={(e) => handleFileUpload(e, month, r.id)}
                                                        type='file'
                                                    />
                                                </> : <TextField
                                                    name={'Source document'}
                                                    // value={!!r?.file?.name ? r.file.name : !!r?.file ? r.file : ''}
                                                    onChange={(e) => handleFileUpload(e, month, r.id)}
                                                    type='file'
                                                />}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" sx={{ m: 2, }} onClick={handleAddAsset}>Add entry</Button>
                    <Button variant="contained" sx={{ m: 2, }} onClick={handleDownstreamDistributionSubmission}>Submit data</Button>
                </>}
        </>
    )
}

export default DownstreamDistributionDataCapturing;