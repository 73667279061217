import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react'
import PetrolDataCapturingMobile from './PetrolDataCapturingMobile';
import PetrolDataCapturingStationary from './PetrolDataCapturingStationary';

export default function Petrol() {
    const [selected, setSelected] = useState('Petrol - Mobile') // removed Petrol - Stationary
    const petrolCategories = ['Petrol - Mobile']

    // const handleAssetPetrolAssetSelection = () => {

    // }
    // const handlePetrolUnitSelection = () => {

    // }

    // const handlePetrolInputChange = () => {

    // }

    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {petrolCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: c === 'Petrol - Stationary' ? 'none' : 'block', mb: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catOne', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={9}>
                {selected === 'Petrol - Mobile' ?
                    <PetrolDataCapturingMobile></PetrolDataCapturingMobile> : <PetrolDataCapturingStationary></PetrolDataCapturingStationary>


                }
            </Grid>
        </>
    );
}

/*
Select Category 1->Select Petrol->Select Petrol (mobile) ->
Select month ->Select asset from drop down list->
Select unit from drop down list (rand or Litres)->
Manually Enter petrol mobile consumption for the selected asset in the selected month with the corresponding unit->
Upload corresponding source documentation

Users should be able to see the annual sum table for corresponding line items in the TOTALS tab (i.e. 12 month total).

*/
