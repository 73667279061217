import { API_GET_BIOCHAR_PRODUCTION, API_GET_FEEDSTOCK_DELIVERED } from './constants/tigercat-dashboard-constants';
import {
    GET_BIOCHAR_PRODUCTION_SUCCESS,
    GET_BIOCHAR_PRODUCTION_FAIL,
    GET_FEEDSTOCK_DELIVERED_SUCCESS,
    GET_FEEDSTOCK_DELIVERED_FAIL
} from './types/tigercat-dashboard-types';

import { setAlert } from '../alert';
import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
};

export const getBiocharProduction = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_BIOCHAR_PRODUCTION + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_BIOCHAR_PRODUCTION_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_BIOCHAR_PRODUCTION_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getFeedstockDelivered = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_FEEDSTOCK_DELIVERED + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_FEEDSTOCK_DELIVERED_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_FEEDSTOCK_DELIVERED_FAIL,
            payload: err.response.data.detail,
        });
    }
};