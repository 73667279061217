import React, { useEffect, useState } from 'react';
import {
    Box,
    Table, TableBody, TableContainer, TextField, TableRow, TableHead, TableCell, Paper, Button
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addIntensityMetric, getIntensityMetrics } from '../../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/08-intensity-metric-actions';

export default function IntensityMetrics() {
    // { farmId }
    const dispatch = useDispatch()
    const { intensityMetricData } = useSelector(state => state.directGhgEmissionsAndRemovalsForIntensityMetrics)
    // const [editedRows, setEditedRows] = useState({});
    const year = localStorage.getItem('year')
    const group = localStorage.getItem('group')
    // const company = localStorage.getItem('company')
    const [intensityMetricState, setIntensityMetricState] = useState([])

    const handleInputChange = (id, value) => {
        setIntensityMetricState(prevState =>
            prevState.map(metric =>
                metric._id === id ? { ...metric, value, edited: true } : metric
            )
        );

    };


    useEffect(() => {
        dispatch(getIntensityMetrics(group, year));
    }, [dispatch, group]);

    useEffect(() => {
        setIntensityMetricState(intensityMetricData?.data || []);
    }, [intensityMetricData]);

    const handleFormSubmit = () => {
        const editedMetrics = intensityMetricState.filter(metric => metric.edited);
        dispatch(addIntensityMetric(editedMetrics)).then(() => {
            dispatch(getIntensityMetrics(group, year))
        })
    };

    const columns = [
        {
            field: 'intensityMetric', headerName: 'Intensity Metric',
            flex: 1,

        },
        {
            field: 'unit',
            headerName: 'Unit',
            flex: 1,
        },
        {
            field: 'value',
            headerName: 'Value',
            flex: 1,
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
        }
    ]

    return (
        <Box sx={{ width: '100%' }} mt={5}>
            <Box sx={{ height: '100%' }}>
                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((c, i) => {
                                    return (

                                        <TableCell key={i} align="left">
                                            {c.headerName}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {intensityMetricState?.map((d, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {d.intensity_metric}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.unit}
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                required={true}
                                                fullWidth
                                                name={'value'}
                                                value={!!d.value ? d.value : ''}
                                                onChange={(e) => handleInputChange(d._id, e.target.value)}
                                                type="text"
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.created_at.split('T')[0]}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button variant="contained" sx={{ m: 2 }} onClick={handleFormSubmit}>Submit data</Button>
            </Box>
        </Box>
    );
}
