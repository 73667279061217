import React, { useEffect, useState } from 'react';
import {
    Box,
    Table, TableBody, TableContainer, TextField, TableRow, TableHead, TableCell, Paper, Button
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addEmployeeCommuting, getEmployeeCommuting, addEmployeeCommutingResponseRate, getEmployeeCommutingResponseRates } from '../../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/09-employee-commuting-actions';


export default function EmployeeCommutingDataCapturing({ selected }) {
    // { farmId }
    const dispatch = useDispatch()
    const { employeeCommutingData } = useSelector(state => state.directGhgEmissionsAndRemovalsForEmployeeCommuting)
    const { employeeCommutingResponseRateData } = useSelector(state => state.directGhgEmissionsAndRemovalsForEmployeeCommuting)

    // const [editedRows, setEditedRows] = useState({});
    const year = localStorage.getItem('year')
    const group = localStorage.getItem('group')
    // const company = localStorage.getItem('company')
    const [employeeCommutingState, setEmployeeCommutingState] = useState([])
    const [surveyResponseRateState, setSurveyResponseRateState] = useState([])

    const handleEmployeeCommutingInputChange = (id, value, name) => {
        setEmployeeCommutingState(prevState =>
            prevState.map(ec => {
                if (name === 'annualKilometersTravalled') {
                    return ec._id === id ? { ...ec, annual_kilometers_travelled: value, edited: true } : ec
                } else if (name === 'amountOfPeople') {
                    return ec._id === id ? { ...ec, amount_of_people: value, edited: true } : ec
                }
            })
        );

    };

    const handleResponseRateInputChange = (id, value, name) => {
        setSurveyResponseRateState(prevState =>
            prevState.map(srr => {
                if (name === 'numberOfEmployees') {
                    return srr._id === id ? { ...srr, number_of_employees: value, edited: true } : srr
                } else if (name === 'numberOfSurveyResults') {
                    return srr._id === id ? { ...srr, number_of_survey_results: value, edited: true } : srr
                }
            }
            )
        );
    }


    useEffect(() => {
        if (group !== '1')
            selected = 'none'
        dispatch(getEmployeeCommuting(group, year, selected));
        dispatch(getEmployeeCommutingResponseRates(group, year, selected));
    }, [dispatch, group, selected]);

    useEffect(() => {
        setEmployeeCommutingState(employeeCommutingData?.data || []);
    }, [employeeCommutingData]);

    useEffect(() => {
        setSurveyResponseRateState(employeeCommutingResponseRateData?.data || []);
    }, [employeeCommutingResponseRateData]);

    const handleEmployeeCommutingSubmit = () => {
        if (group !== '1')
            selected = 'none'
        const editedEntries = employeeCommutingState.filter(ecs => ecs.edited);
        dispatch(addEmployeeCommuting(editedEntries)).then(() => {
            dispatch(getEmployeeCommuting(group, year, selected))
        })
    };

    const handleResponseRateSubmit = () => {
        if (group !== '1')
            selected = 'none'
        const editedEntries = surveyResponseRateState.filter(srr => srr.edited);
        dispatch(addEmployeeCommutingResponseRate(editedEntries)).then(() => {
            dispatch(getEmployeeCommutingResponseRates(group, year, selected))
        })
    };

    const columns = [
        {
            field: 'modeOfTransport',
            headerName: 'Mode of Transport',
            flex: 1,
        },
        {
            field: 'annualKilometersTravelled', headerName: 'Annual Kilometers Travelled',
            flex: 1,

        },
        {
            field: 'peoplePerModeOfTransport',
            headerName: '# People per Mode of Transport',
            flex: 1,
        }
    ]

    return (
        <Box sx={{ width: '100%' }} mt={5}>
            <Box sx={{ height: '100%' }}>
                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {surveyResponseRateState?.map((d, i) => {
                                return (
                                    <Box key={i}>
                                        <TableRow >
                                            <TableCell align="left">
                                                Number of Employees
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    required={true}
                                                    name={'numberOfEmployees'}
                                                    value={!!d?.number_of_employees ? d.number_of_employees : ''}
                                                    onChange={(e) => handleResponseRateInputChange(d?._id, e.target.value, e.target.name)}
                                                    type="text"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                Number of Survey Results
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    required={true}
                                                    name={'numberOfSurveyResults'}
                                                    value={!!d?.number_of_survey_results ? d.number_of_survey_results : ''}
                                                    onChange={(e) => handleResponseRateInputChange(d?._id, e.target.value, e.target.name)}
                                                    type="text"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ border: '2px solid black' }}>
                                            <TableCell align="left">
                                                <b>Survey Response Rate</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Box ml={1.5}>
                                                    <b>
                                                        {d?.number_of_employees && d?.number_of_survey_results ? (d.number_of_survey_results / d.number_of_employees) * 100 : 0}%
                                                    </b>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </Box>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button variant="contained" sx={{ mt: 2, ml: 2, mb: 5 }} onClick={handleResponseRateSubmit}>Submit data</Button>
                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((c, i) => {
                                    return (

                                        <TableCell key={i} align="left">
                                            {c.headerName}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {employeeCommutingState?.map((d, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {d?.mode_of_transport}
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                required={true}
                                                fullWidth
                                                name={'annualKilometersTravalled'}
                                                value={!!d?.annual_kilometers_travelled ? d?.annual_kilometers_travelled : ''}
                                                onChange={(e) => handleEmployeeCommutingInputChange(d?._id, e.target.value, e.target.name)}
                                                type="text"
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                required={true}
                                                fullWidth
                                                name={'amountOfPeople'}
                                                value={!!d?.amount_of_people ? d?.amount_of_people : ''}
                                                onChange={(e) => handleEmployeeCommutingInputChange(d?._id, e.target.value, e.target.name)}
                                                type="text"
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button variant="contained" sx={{ m: 2 }} onClick={handleEmployeeCommutingSubmit}>Submit data</Button>
            </Box>
        </Box>
    );
}
