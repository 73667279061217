import { setAlert } from '../../alert';
import {
    API_POST_ADD_INTENSITY_METRIC,
    API_GET_INTENSITY_METRICS,
} from '../constants/01-direct-ghg-emissions-and-removals-constants';
import {
    ADD_INTENSITY_METRIC_SUCCESS,
    ADD_INTENSITY_METRIC_FAIL,
    GET_INTENSITY_METRICS_SUCCESS,
    GET_INTENSITY_METRICS_FAIL
} from '../types/01-direct-ghg-emissions-and-removals-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};

export const addIntensityMetric = (formData) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_ADD_INTENSITY_METRIC,
            formData,
            config
        );
        dispatch({
            type: ADD_INTENSITY_METRIC_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: ADD_INTENSITY_METRIC_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getIntensityMetrics = (group, year) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_INTENSITY_METRICS + `/${group}` + `/${year}`,
            config
        );
        dispatch({
            type: GET_INTENSITY_METRICS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_INTENSITY_METRICS_FAIL,
            payload: err.response.data.detail,
        });
    }
};