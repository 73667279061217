import { setAlert } from '../../alert';

import {
    POST_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS,
    POST_DOWNSTREAM_DISTRIBUTION_DATA_FAIL,
    GET_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS,
    GET_DOWNSTREAM_DISTRIBUTION_DATA_FAIL,
    GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_SUCCESS,
    GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_FAIL,
} from '../types/03-indirect-ghg-emissions-from-transportation';

import {
    API_POST_DOWNSTREAM_DISTRIBUTION_DATA,
    API_GET_DOWNSTREAM_DISTRIBUTION_DATA,
    API_GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS,
} from '../constants/03-indirect-ghg-emissions-from-transportation';

import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    // withCredentials: true,
};

export const postDownstreamDistributionData = (formData, year, company, group, facility, type) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_DOWNSTREAM_DISTRIBUTION_DATA + `/${year}/${company}/${group}/${facility}/${type}`,
            formData,
            config
        );
        dispatch({
            type: POST_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_DOWNSTREAM_DISTRIBUTION_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getDownstreamDistributionData = (year, facility, type) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_DOWNSTREAM_DISTRIBUTION_DATA + `/${year}/${facility}/${type}`,
            config
        );
        dispatch({
            type: GET_DOWNSTREAM_DISTRIBUTION_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_DOWNSTREAM_DISTRIBUTION_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getDownstreamDistributionDataTotals = (year, facility, type) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS + `/${year}/${facility}/${type}`,
            config
        );
        dispatch({
            type: GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_DOWNSTREAM_DISTRIBUTION_DATA_TOTALS_FAIL,
            payload: err.response.data.detail,
        });
    }
};